<template>
  <div class="tzhh">
    <div class="header">
    </div>
    <div class="content">
        <div class="images ppgs" @click="golist('/ppgs')"></div>
        <div class="images zjtx" @click="golist()"></div>
      <div class="images csrs" @click="golist('/csrs')"></div>
      <div class="images lyqy" @click="golist()"></div>
      <div class="images zbzx" @click="golist('/zbzx')"></div>
      <div class="images gjgx" @click="golist()"></div>
      <div class=" itzhh"></div>
    </div>
  </div>
</template>

<script>

import {broadcastcenterindex} from "../../request/moudle/tzhh";

export default {
  name: "index",
  data(){
    return {

    }
  },
  created() {
  },
  methods:{

    golist(url){
      this.$router.push({
        path:url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  background: #fff;
  width: 1200px;
  margin: 144px auto;
  position: relative;
}
.tzhh {
  background: #fff;
  height:1000px;
  background: url("../../assets/banner/tzhhindex.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
  .header{
    height:88px;
  }
  .images{
    width: 210px;
    height: 100px;
    position: absolute;
    cursor: pointer;
  }
  .ppgs{
    background: url("../../assets/tzhh/ppgs.png");
    background-size: cover;
    left: 130px;
  }
  .zjtx{
    background: url("../../assets/tzhh/zjtx.png");
    background-size: cover;
    right: 130px;
  }
  .csrs{
    background: url("../../assets/tzhh/csrs.png");
    background-size: cover;
    top: 207px;
    left: 0px;
  }
  .lyqy{
    background: url("../../assets/tzhh/lyqy.png");
    background-size: cover;
    top: 207px;
    right: 0px;
  }
  .zbzx{
    background: url("../../assets/tzhh/zbzx.png");
    background-size: cover;
    top: 407px;
    left: 100px;
  }
  .gjgx{
    background: url("../../assets/tzhh/gjgx.png");
    background-size: cover;
    top: 407px;
    right: 80px;
  }
  .itzhh{
    width: 296px;
    height: 95px;
    position: absolute;
    top:148px;
    background: url("../../assets/tzhh/tzhh.png");
    background-size: cover;
    left: 50%;
    margin-left: -148px;
  }
}
</style>